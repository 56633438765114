<script setup>
const props = defineProps({
  type: String,
  name: String,
  placeholder: String,
  value: [String, Number],
  label: String,
  modelValue: [String, Number],
  required: Boolean,
  errors: Array
})

const errorClass = "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
const validClass = "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-stone-600"
</script>

<template>
  <div>
    <label :for="props.name" class="block text-sm font-semibold text-gray-900 leading-6">
        {{props.label}} <span v-if="props.required" class="text-red-500">*</span>
    </label>
    <div class="relative mt-2 rounded-md shadow-sm">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :type="props.type" :name="props.name" :id="props.name"
        :required="props.required"
        :class="[ props?.errors?.length ? errorClass : validClass]"
        class="block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
        :placeholder="props.placeholder"
        :aria-invalid="props?.errors?.length ? errors[0] : false"
        :aria-describedby="props?.errors?.length ? props.name+'-error' : null"
      >
      <div v-if="props?.errors?.length" class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <svg class="w-5 h-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <p v-for="error in props?.errors" class="mt-2 text-sm text-red-600" :id="props.name+'-error'">
      {{ error }}
    </p>
  </div>
</template>
